import React from "react"
import { Helmet } from "react-helmet"

import OfferBase from "../../../components/offer"

const Offer = () => {
  return (
    <OfferBase offerTitle={'Mezoterapia Igłowa'}>
      <Helmet title={"Mezoterapia Igłowa - Zabiegi Medycyny Estetycznej | Świdnica"} />
    </OfferBase>
  )
}

export default Offer
